import _utils from "./utils";
import _bind from "./helpers/bind";
import _Axios from "./core/Axios";
import _mergeConfig from "./core/mergeConfig";
import _defaults from "./defaults";
import _CanceledError from "./cancel/CanceledError";
import _CancelToken from "./cancel/CancelToken";
import _isCancel from "./cancel/isCancel";
import _data from "./env/data";
import _toFormData from "./helpers/toFormData";
import _AxiosError from "../lib/core/AxiosError";
import _spread from "./helpers/spread";
import _isAxiosError from "./helpers/isAxiosError";
var exports = {};
var utils = _utils;
var bind = _bind;
var Axios = _Axios;
var mergeConfig = _mergeConfig;
var defaults = _defaults;
/**
 * Create an instance of Axios
 *
 * @param {Object} defaultConfig The default config for the instance
 * @return {Axios} A new instance of Axios
 */

function createInstance(defaultConfig) {
  var context = new Axios(defaultConfig);
  var instance = bind(Axios.prototype.request, context); // Copy axios.prototype to instance

  utils.extend(instance, Axios.prototype, context); // Copy context to instance

  utils.extend(instance, context); // Factory for creating new instances

  instance.create = function create(instanceConfig) {
    return createInstance(mergeConfig(defaultConfig, instanceConfig));
  };

  return instance;
} // Create the default instance to be exported


var axios = createInstance(defaults); // Expose Axios class to allow class inheritance

axios.Axios = Axios; // Expose Cancel & CancelToken

axios.CanceledError = _CanceledError;
axios.CancelToken = _CancelToken;
axios.isCancel = _isCancel;
axios.VERSION = _data.version;
axios.toFormData = _toFormData; // Expose AxiosError class

axios.AxiosError = _AxiosError; // alias for CanceledError for backward compatibility

axios.Cancel = axios.CanceledError; // Expose all/spread

axios.all = function all(promises) {
  return Promise.all(promises);
};

axios.spread = _spread; // Expose isAxiosError

axios.isAxiosError = _isAxiosError;
exports = axios; // Allow use of default import syntax in TypeScript

exports.default = axios;
export default exports;